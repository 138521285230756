<template>
  <section class="invoice-add-wrapper">
    <!-- Alert: No item found -->

    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12">
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <b-form
            @submit.prevent=" handleSubmit(onSubmit) "
            @reset.prevent="resetForm"
          >
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-body>
                <b-row>
                  <b-col
                    cols="12"
                    lg="3"
                  >
                    <div class="">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >

                        <b-form-group
                          label=" نوع العملية"
                          label-for="payment-method"
                          :state="errors.length > 0 ? false : null"
                        >
                          <v-select
                            v-model="Form.type_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="warehouseTRType"
                            disabled
                            :reduce="(val) => val.value"
                            class="mb-2 item-selector-title"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                  </b-col>
                  <b-col
                    v-if="NewpurchaseRequest"
                    cols="12"
                    lg="3"
                  >
                    <div class="">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >

                        <b-form-group
                          label="امر الشراء"
                          label-for="payment-method"
                          :state="errors.length > 0 ? false : null"
                        >
                          <v-select
                            v-model="Form.code"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="AllpurchaseRequest"
                            label="code"
                            :reduce="(val) => val.id"
                            @input="getDetails(Form.code)"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                    xl="3"
                    class="invoice-actions"
                  >
                    <!-- Payment Method -->
                    <div class="">
                      <b-form-group
                        label="Cost Center"
                        label-for="payment-method"
                      />
                      <b-form-input
                        v-model="Form.cost_center"
                        type="text"
                      />
                      <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                    </div>
                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                    xl="3"
                    class="invoice-actions"
                  >
                    <!-- Payment Method -->
                    <div class="">
                      <b-form-group
                        label=" PPa Number"
                        label-for="payment-method"
                      />
                      <b-form-input
                        v-model="Form.ppa_number"
                        type="text"
                      />
                      <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                    </div>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                    xl="3"
                    class="invoice-actions"
                  >
                    <!-- Payment Method -->
                    <div class="">
                      <b-form-group
                        label=" ملاحظة"
                        label-for="payment-method"
                      />
                      <b-form-input
                        v-model="Form.notes"
                        type="text"
                      />
                      <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                    </div>
                  </b-col>
                </b-row>

              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Items Section -->
              <b-card-body class="invoice-padding form-item-section">
                <div
                  ref="form"
                  class="repeater-form"
                >
                  <b-row
                    v-for="(item, index) in Form.details"
                    :key="index"
                    ref="row"
                    class="pb-2"
                  >
                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="12"
                          >
                            <label>
                              التفاصيل
                            </label>
                          </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>

                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="3"
                            lg="3"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules=""
                            >
                              <b-form-group
                                :state="errors.length > 0 ? false : null"
                              >
                                <label>مستودع رئيسي</label>
                                <v-select
                                  v-model="item.main_warehouse_id"
                                  :options="warehouseOption"

                                  label="name"
                                  :clearable="false"
                                  :reduce="(val) => val.id"
                                  @input="getChildWarehouse(item.main_warehouse_id)"
                                />
                              </b-form-group>
                              <b-form-invalid-feedback
                                :state="errors.length > 0 ? false : null"
                              >
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="4"
                            lg="4"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules=""
                            >
                              <b-form-group
                                :state="errors.length > 0 ? false : null"
                              >
                                <label>مستودع فرعي</label>
                                <v-select
                                  v-model="item.sub_warehouse_id"
                                  :options="warehouseChildOption"

                                  label="name"
                                  :clearable="false"

                                  :reduce="(val) => val.id"
                                />
                              </b-form-group>
                              <b-form-invalid-feedback
                                :state="errors.length > 0 ? false : null"
                              >
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >

                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-group
                                :state="errors.length > 0 ? false : null"
                              >
                                <label>المادة</label>
                                <v-select
                                  v-model="item.item_id"
                                  :dir="
                                    $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                  "
                                  :options="ItemData"
                                  label="name"

                                  :clearable="false"

                                  class="mb-2 item-selector-title"
                                  placeholder="المادة "
                                />
                              </b-form-group>
                              <b-form-invalid-feedback
                                :state="errors.length > 0 ? false : null"
                              >
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-group
                                :state="errors.length > 0 ? false : null"
                              >
                                <label>الكمية</label>
                                <b-form-input
                                  v-model="item.quantity"
                                  type="number"
                                  class="mb-2"
                                  placeholder="الكمية "
                                />
                              </b-form-group>
                              <b-form-invalid-feedback
                                :state="errors.length > 0 ? false : null"
                              >
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-group
                                label-for="notes"
                                :state="errors.length > 0 ? false : null"
                              >
                                <label>الواحدة</label>
                                <v-select
                                  v-model="item.unit_id"
                                  :dir="
                                    $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                  "
                                  :options="ubitsOption"
                                  label="en_name"
                                  :clearable="false"
                                  class="mb-2 item-selector-title"
                                  placeholder="الواحدة "
                                />

                              </b-form-group>
                              <b-form-invalid-feedback
                                :state="errors.length > 0 ? false : null"
                              >
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-group
                                :state="errors.length > 0 ? false : null"
                              >
                                <label>العملة</label>
                                <v-select
                                  v-model="item.currency_id"
                                  :dir="
                                    $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                  "
                                  :options="optionCurrency"
                                  label="name"
                                  :clearable="false"
                                  :reduce="(val) => val.id"
                                  class="mb-2 item-selector-title"
                                 
                                />
                              </b-form-group>
                              <b-form-invalid-feedback
                                :state="errors.length > 0 ? false : null"
                              >
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-group
                                label-for="notes"
                                :state="errors.length > 0 ? false : null"
                              >
                                <label>السعر</label>
                                <b-form-input
                                  v-model="item.price"
                                  type="text"
                                  placeholder="السعر "
                                  class="mb-2"
                                />
                              </b-form-group>
                              <b-form-invalid-feedback
                                :state="errors.length > 0 ? false : null"
                              >
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-group
                                label-for="notes"
                                :state="errors.length > 0 ? false : null"
                              >
                                <label> الحالة</label>

                                <v-select
                                  v-model="item.condition"

                                  placeholder="الحالة"
                                  :options="condition"

                                  :clearable="false"
                                  :reduce="(val) => val.value"
                                  class="-2 item-selector-title"
                                />

                              </b-form-group>
                              <b-form-invalid-feedback
                                :state="errors.length > 0 ? false : null"
                              >
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="3"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules=""
                            >
                              <b-form-group
                                label="  القسم "
                                label-for="user-department"
                              >

                                <v-select
                                  v-model="item.department_id"
                                  :options="departmentOptions"
                                  label="name"
  
                                  input-id="user-role"
                                />
                              </b-form-group>
                              <b-form-invalid-feedback
                                :state="errors.length > 0 ? false : null"
                              >
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                          <b-col
                            md="3"
                            xl="4"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules=""
                            >
                              <b-form-group
                                label-for="end_date"
                                :state="errors.length > 0 ? false : null"
                              >
                                <label> تاريخ الصلاحية </label>
                                <flat-pickr
                                  v-model="item.end_date"
                                  class="form-control"
                                  placeholder="حدد تاريخ "
                                />
                              </b-form-group>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>

                          <b-col
                            md="3"
                            xl="4"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules=""
                            >
                              <b-form-group
                                label-for="recive_date"
                                :state="errors.length > 0 ? false : null"
                              >
                                <label> تاريخ الاستلام </label>
                                <flat-pickr
                                  v-model="item.recive_date"
                                  class="form-control"
                                  placeholder="حدد تاريخ "
                                />
                              </b-form-group>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                          <b-col
                            md="3"
                            xl="3"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules=""
                            >
                              <b-form-group
                                label-for="employe"
                                :state="errors.length > 0 ? false : null"
                              >

                                <label> المورد</label>
                                <v-select
                                  v-model="item.vendor_id"
                                  label="name"
                                  :options="optionVendor"
                                  :reduce="(val) => val.id"
                                />
                              </b-form-group>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="4"
                            md="4"
                            xl="3"
                          >
                            <!-- Payment Method -->
                            <div class="">
                              <b-form-group
                                label=" رقم الفاتورة "
                                label-for="payment-method"
                              />
                              <b-form-input
                                v-model="item.pill_number"
                                type="text"
                              />
                              <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                            </div>
                          </b-col>
                          <b-col
                            cols="4"
                            md="4"
                            xl="3"
                          >
                            <!-- Payment Method -->
                            <div class="">
                              <b-form-group
                                label=" رقم أمر الشراء"
                                label-for="payment-method"
                              />
                              <b-form-input
                                v-model="item.po_number"
                                type="text"
                              />
                              <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->
                            </div>

                          </b-col>
                          <b-col
                            cols="12"
                            md="4"
                          >
                            <div class="">
                              <b-form-group
                                label="  اسم المستلم  "
                                label-for="user-department"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  name="user"
                                  rules=""
                                >
                                  <v-select
                                    v-model="item.recived_by_user_id"
                                    :options="usersOptions"

                                    :reduce="(val) => val.value"
                                    input-id="user-role"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </div>
                          </b-col>
                        </b-row>
                        <div
                          class="d-flex flex-column justify-content-between border-left py-50 px-25"
                        >
                          <feather-icon
                            v-if="index != 0"
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeItem(index)"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="addNewItemInItemForm"
                >
                  <span>
                    اضافة مادة اخرى
                  </span>
                </b-button>
              </b-card-body>

              <!-- Invoice Description: Total -->

              <!-- Spacer -->
              <hr class="invoice-spacing">
              <b-col xl="3">
                <b-button
                  variant="purple"
                  class="shadow mb-3"
                  @click="downloadExcel"
                >
                  <vue-excel-xlsx
                    ref="childComponent"
                    :data="ItemDetails"
                    :columns="columns"
                    :file-name="filename"
                    :file-type="'xlsx'"
                    :sheet-name="'sheetname'"
                    class="button-excel"
                  />
                  <span style="color:#fff"> طباعة المسودة  </span>
                </b-button>
              </b-col>
              <b-col
                class="border-Primary mb-10"
                md="12"
                xl="4"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  type="submit"
                >
                  <feather-icon
                    icon="CheckIcon"
                    class="mr-50 mb-10"
                  />
                  <span class="align-middle"> انشاء</span>
                </b-button>

              </b-col>
              <!-- Note -->
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- Right Col: Card -->
    </b-row>
    <b-row />

    <!-- <invoice-sidebar-send-invoice />
          <invoice-sidebar-add-payment /> -->
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import Vue from 'vue'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,

  BForm,
  BFormGroup,
  BFormInput,

  BFormInvalidFeedback,

  VBToggle,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import financeStoreModule from '../financeStoreModule.js'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    required,
    ValidationProvider,
    ValidationObserver,
    financeStoreModule,
    BFormInvalidFeedback,
    BCard,
    BCardBody,
    BButton,

    BForm,
    BFormGroup,
    BFormInput,

    // eslint-disable-next-line vue/no-unused-components
    flatPickr,
    vSelect,

  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
      file: '',
      body: '',
      vendor_id: '',
      optionVendor: [],
      NewpurchaseRequest: false,
      AllpurchaseRequest: [],
      purchaseRequest: [],
      ItemDetails: [

      ],
      columns: [
        {
          label: 'النوع',
          field: 'type_id',
        },
        {
          label: 'تاريخ الملف',
          field: 'RecevieDate',
        },

        {
          label: 'المادة',
          field: 'item_id.name',
        },
        {
          label: 'الواحدة',
          field: 'unit_id.name',
        },
        {
          label: 'الكمية',
          field: 'quantity',
        },
        {
          label: ' تاريخ الصلاحية ',
          field: 'end_date',
        },
        {
          label: ' تاريخ الاستلام ',
          field: 'recive_date',
        },
        {
          label: '   القسم',
          field: 'department_id.name',
        },
        {
          label: '   رقم الفاتورة ',
          field: 'pill_number',
        },

      ],
      filename: 'ادخال مستودع',
    }
  },

  // Reset Tr Height if data changes
  watch: {
    // eslint-disable-next-line func-names
    'invoiceData.items': function () {
      this.initTrHeight()
    },
  },
  mounted() {
    this.initTrHeight()
    this.optionVendor = []
    this.$http.get('/api/v1/vendor').then(res => {
      // console.log('itemres',res);
      this.optionVendor = res.data.data

      // console.log(this.optionVendor);
    })
    this.$http.get('/api/v1/get-procurment-need-entering').then(res => {
      if (res.data.data.length > 0) {
        this.NewpurchaseRequest = true
        this.AllpurchaseRequest = res.data.data
        this.purchaseRequest = res.data.data
      }
    })
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    getDetails(id) {
   // console.log("getDetails")
      const item = this.AllpurchaseRequest.find(el => el.id == id)
         // console.log(item)
      this.Form.details = item.sync_details.map(el => ({
        ...el,
        item_id: el.item,
        unit_id: el.unit,
    
        department_id: el.department,
      }))
    
    },
    async downloadExcel() {
      return new Promise((resolve, reject) => {
        this.ItemDetails = this.Form.details

        this.ItemDetails[0].type_id = 'ادخال مستودع'
        this.ItemDetails[0].RecevieDate = this.Form.receive_date

        setTimeout(() => {
          this.$refs.childComponent.exportExcel()
        }, 2000)
      })
    },

    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0)
      })
    },
  },
  setup() {
    const FINANCE_APP_STORE_MODULE_NAME = 'app-finance'

    // Register module
    if (!store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.registerModule(FINANCE_APP_STORE_MODULE_NAME, financeStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.unregisterModule(FINANCE_APP_STORE_MODULE_NAME) }
    })

    const Form = ref({
      type_id: 6,
      from_party_id: null,

      user_material_order_id: 1,

      logistic_officer_signature: null,
      project_manager_signature: null,
      code: null,
      cost_center: null,
      department_id: '',
      ppa_number: null,
      notes: '',
      details: [
        {
          item_id: '',
          main_warehouse_id: '',
          sub_warehouse_id: '',
          quantity: 1,
          notes: '',
          unit_id: '',
          condition: '',
          end_date: '',
          currency_id: 1,
          price: '',
          recive_date: '',
          department_id: '',
          pill_number: '',
          po_number: '',
          vendor_id: '',
          recived_by_user_id: '',

        },
      ],
    })

    const invoiceData = ref(null)
    const accountList = ref([])
    const SubAccountList = ref([])

    const itemFormBlankItem = {
      item: null,
      cost: 0,
      qty: 0,
      description: '',
    }

    const itemsOptions = ref([
      {
        item_id: '',
        quantity: null,
        currency_id: 1,
        price: '',
        unit_id: '',

      },

    ])
    const addNewItemInItemForm = () => {
      Form.value.details.push(
        {
          item_id: '',
          quantity: null,
          currency_id: 1,
          price: '',
          unit_id: '',

        },
      )
    }
    const removeItem = index => {
      Form.value.details.splice(index, 1)
    }
    const ItemData = ref([])
    const optionCurrency = ref([])
    store.dispatch('app-finance/GetCurrency').then(response => {
      // console.log('ubitsOption,', optionCurrency)
      optionCurrency.value = response
    })
    const ubitsOption = ref([])
    store.dispatch('app-finance/GetUnits').then(response => {
      // console.log('ubitsOption,', ubitsOption)
      ubitsOption.value = response
    })
    store.dispatch('app-finance/GetItem').then(response => {
      // console.log(response)
      ItemData.value = response.map(el => ({
        name: `${el.name} ${el.code}`,
        id: el.id,
      }))
    })
    const warehouseOption = ref([])
    const warehouseChildOption = ref([])
    store.dispatch('app-finance/getParentTypeWarehouse').then(response => {
      response.data.forEach(el => {
        warehouseOption.value.push({
          id: el.id,
          name: el.name,
        })
      })
    })

    const getChildWarehouse = id => {
      warehouseChildOption.value = []
      store.dispatch('app-finance/getChildTypeWarehouse', id).then(response => {
        response.data.forEach(el => {
          warehouseChildOption.value.push({
            id: el.id,
            name: el.name,
          })
        })
      })
    }
    const updateItemForm = (index, val) => {
      const { cost, qty, description } = val
      invoiceData.value.items[index].cost = cost
      invoiceData.value.items[index].qty = qty
      invoiceData.value.items[index].description = description
    }
    const donorOption = ref([])
    const billerOption = ref([])
    store.dispatch('app-finance/GetDonor').then(response => {
      // console.log(response)
      donorOption.value = response
    })
    store.dispatch('app-finance/GetBiller').then(response => {
      // console.log(response)
      billerOption.value = response
    })
    const outComeOption = ref([])
    const outPutOption = ref([])
    const partTypeTo = ref('')
    store.dispatch('app-finance/GetOutCome').then(response => {
      // console.log(response)
      outComeOption.value = response
    })
    store.dispatch('app-finance/GetoutPut').then(response => {
      // console.log(response)
      outPutOption.value = response
    })
    store.dispatch('app-finance/GetAccount').then(response => {
      // console.log(response)
      accountList.value = response
    })
    const partOptionTo = ref([])
    const getPartyTypeTo = id => {
      partOptionTo.value = []
      store.dispatch('app-finance/getPartyType', { id }).then(response => {
        // console.log('ubitsOption,', response)
        if (id == 1) {
          response.data.forEach(el => {
            if (el.details.first_name) {
              partOptionTo.value.push({
                id: el.id,
                name: el.details.first_name,
              })
            }
          })
        } else {
          response.data.forEach(el => {
            if (el.details.name) {
              partOptionTo.value.push({
                id: el.id,
                name: el.details.name,
              })
            }
          })
        }
      })
    }
    const getSubAccount = id => {
      SubAccountList.value = []
      store.dispatch('app-finance/getSubAccount', { id }).then(response => {
        // console.log('ubitsOption,', response)

        response.forEach(el => {
          SubAccountList.value.push({
            id: el.id,
            name: el.name,
          })
        })
      })
    }
    const departmentOptions = ref([])
    store.dispatch('app-finance/GetDepartment').then(response => {
      departmentOptions.value = response
    })
    const partOption = ref([])

    const getPartyType = id => {
      partOption.value = []
      store.dispatch('app-finance/getPartyType', { id }).then(response => {
        // console.log('ubitsOption,', response)
        if (id == 1) {
          response.data.forEach(el => {
            partOption.value.push({
              id: el.id,
              name: el.details.first_name,
            })
          })
        } else {
          response.data.forEach(el => {
            partOption.value.push({
              id: el.id,
              name: el.details.name,
            })
          })
        }
      })
    }

    const warehouseTRType = [
      {
        value: 6,
        label: 'ادخال',
      },
      {
        value: 2,
        label: 'نقل',
      },
      {
        value: 3,
        label: 'مرتجع',
      },
      {
        value: 4,
        label: 'تلف',
      },
      {
        value: 5,
        label: 'توزيع',
      },
    ]

    const condition = [
      {
        value: 1,
        label: 'جيد',
      },
      {
        value: 0,
        label: 'ممتاز',
      },
      {
        value: 2,
        label: 'وسط',
      },
      {
        value: 3,
        label: 'ضعيف',
      },

    ]
    const partType = ref('')
    const usersOptions = ref([])
    store.dispatch('app-finance/GetUsers').then(response => {
      // console.log(response)
      usersOptions.value = []

      response.forEach(el => {
        usersOptions.value.push({ value: el.id, label: `${el.first_name} ${el.last_name} ` })
      })
    })
    const partTypeOption = [
      {
        value: 1,
        label: 'مستخدم',
      },
      {
        value: 2,
        label: 'مستودع',
      },
      {
        value: 3,
        label: 'مورد',
      },
    ]
    const pr_code = ref('')

    const saveTransaction = () => {
      const data = {
        transaction_id: Form.value.code,
        pr_code: pr_code.value,
      }
      store.dispatch('app-finance/afterCreateTransaction', data).then(response => {
        // Form.value.from_party_id = '',
        Form.value.to_party_id = '',
        Form.value.notes = '',
        Form.value.details = [{
          item_id: '',
          quantity: 1,
          notes: '',

        }]

        Form.value.cost_center = null,

        Form.value.department_id = '',
        Form.value.ppa_number = '',
        resetForm()
        Vue.swal({
          title: '',
          text: 'تمت أضافة الطلب بنجاح',
          icon: 'success',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    }
    const { refFormObserver, getValidationState, resetForm } = formValidation(() => {})
    const onSubmit = () => {
   
      // console.log("Form.value.details")
      // console.log(Form.value.details)
      // Form.value.department_id = Form.value.details[0].department_id.name
      // console.log(Form.value)
      Form.value.details = Form.value.details.map(obj => ({
        ...obj,
        item_id: obj.item_id.id,
        department_id: obj.department_id ? obj.department_id.id : null,
        unit_id: obj.unit_id.id,

        // other properties you might want to include from the original object
      }))
      store.dispatch('app-finance/createTransaction', Form.value).then(response => {
        // Form.value.type_id = '',
        pr_code.value = response.data.data.code
        if (Form.value.code != null) {
          saveTransaction()
        } else {
          Form.value.to_party_id = '',
          Form.value.notes = '',
          Form.value.details = [{
            item_id: '',
            quantity: 1,
            notes: '',

          }]

          Form.value.cost_center = null,

          Form.value.department_id = '',
          Form.value.ppa_number = '',
          resetForm()
          Vue.swal({
            title: '',
            text: 'تمت أضافة الطلب بنجاح',
            icon: 'success',
            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    }

    return {
      usersOptions,

      warehouseOption,
      getChildWarehouse,
      warehouseChildOption,
      condition,
      departmentOptions,
      Form,
      getPartyTypeTo,
      partOptionTo,
      partTypeTo,
      refFormObserver,
      getValidationState,
      onSubmit,
      saveTransaction,
      resetForm,
      addNewItemInItemForm,
      removeItem,
      ItemData,
      optionCurrency,
      ubitsOption,
      accountList,
      getSubAccount,
      SubAccountList,
      billerOption,
      donorOption,
      outComeOption,
      outPutOption,
      getPartyType,
      partOption,
      partType,
      partTypeOption,
      invoiceData,
      itemsOptions,
      updateItemForm,
      itemFormBlankItem,
      warehouseTRType,
      pr_code,

    }
  },
}
</script>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  </style>

  <style lang="scss" scoped>
  @import "~@core/scss/base/pages/app-invoice.scss";

  .form-item-section {
    background-color: $product-details-bg;
  }
  button.btn.btn-outline-primary {
      margin: 1.375rem !important;
  }

  .form-item-action-col {
    width: 27px;
  }

  .repeater-form {
    // overflow: hidden;
    transition: 0.35s height;
  }

  .v-select {
    &.item-selector-title,
    &.payment-selector {
      background-color: #fff;

      .dark-layout & {
        background-color: unset;
      }
    }
  }

  .dark-layout {
    .form-item-section {
      background-color: $theme-dark-body-bg;

      .row .border {
        background-color: $theme-dark-card-bg;
      }
    }
  }
  button.btn.me-20.btn-outline-danger {
    margin-right: 17px;
  }
  </style>
